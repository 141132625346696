div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrap-table {
  width: 110%;
}

/* table general */
table {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* table header & cells general*/
table th, table td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

/* table header background */
table thead {
  height: 60px;
  background: #36304a;
}

/* table header font */
.table100-head th{
  font-size: 20px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

/* row general */
tbody tr {
  font-size: 17px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
  height: 40px
}

/* row background color (even rows) */
tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

/* row effects */
tbody tr:hover {
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}
